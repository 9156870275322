var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Date *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.date,
                              expression: "visitorSupportRequestForm.date"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "date", name: "", id: "" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.date
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "date",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Type de structure *")
                          ]),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.structureTypes,
                              placeholder: "Select user structure",
                              "allow-empty": false
                            },
                            on: { input: _vm.onchangeStructureType },
                            scopedSlots: _vm._u([
                              {
                                key: "singleStruct",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value:
                                _vm.visitorSupportRequestForm.structure_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.visitorSupportRequestForm,
                                  "structure_type",
                                  $$v
                                )
                              },
                              expression:
                                "visitorSupportRequestForm.structure_type"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "formrow-password-input" } },
                            [_vm._v("Structure d'accueil *")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.structures,
                              placeholder: "Select user structure",
                              "allow-empty": false
                            },
                            on: { input: _vm.onchangeStructure },
                            scopedSlots: _vm._u([
                              {
                                key: "singleStruct",
                                fn: function(ref) {
                                  var struct = ref.struct
                                  return [_vm._v(_vm._s(struct.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.visitorSupportRequestForm.structure,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.visitorSupportRequestForm,
                                  "structure",
                                  $$v
                                )
                              },
                              expression: "visitorSupportRequestForm.structure"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "formrow-password-input" } },
                          [_vm._v("Motif de la demande *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.reason,
                              expression: "visitorSupportRequestForm.reason"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.reason
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "reason",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Imputation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.visitorSupportRequestForm.analyticAccount,
                              expression:
                                "visitorSupportRequestForm.analyticAccount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.analyticAccount
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "analyticAccount",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Attachement ")
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "file" }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "formrow-password-input" } },
                          [_vm._v("Date d'arrivée *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.dateIn,
                              expression: "visitorSupportRequestForm.dateIn"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "date" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.dateIn
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "dateIn",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Date de sortie *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.dateOut,
                              expression: "visitorSupportRequestForm.dateOut"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "date" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.dateOut
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "dateOut",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Services *")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-3 inlb",
                              attrs: { switch: "" },
                              model: {
                                value: _vm.visitorSupportRequestForm.needRest,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.visitorSupportRequestForm,
                                    "needRest",
                                    $$v
                                  )
                                },
                                expression: "visitorSupportRequestForm.needRest"
                              }
                            },
                            [_vm._v("Restauration")]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-3 inlb",
                              attrs: { switch: "" },
                              model: {
                                value:
                                  _vm.visitorSupportRequestForm.needHosting,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.visitorSupportRequestForm,
                                    "needHosting",
                                    $$v
                                  )
                                },
                                expression:
                                  "visitorSupportRequestForm.needHosting"
                              }
                            },
                            [_vm._v("Hébergement")]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-3 inlb",
                              attrs: { switch: "" },
                              model: {
                                value:
                                  _vm.visitorSupportRequestForm.needTransport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.visitorSupportRequestForm,
                                    "needTransport",
                                    $$v
                                  )
                                },
                                expression:
                                  "visitorSupportRequestForm.needTransport"
                              }
                            },
                            [_vm._v("Transport")]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Entreprise *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.companyName,
                              expression:
                                "visitorSupportRequestForm.companyName"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "", id: "" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.companyName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "companyName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("h3", [_vm._v("Visiteurs: ")]),
                  _c("hr"),
                  _c(
                    "div",
                    [
                      _vm._l(_vm.visitorSupportRequestForm.visitors, function(
                        visitor,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: visitor.id, staticClass: "row" },
                          [
                            _c("div", { staticClass: "form-group col-lg-3" }, [
                              _c("label", { attrs: { for: "message" } }, [
                                _vm._v("Nom complet")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: visitor.fullName,
                                    expression: "visitor.fullName"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "subject", type: "text" },
                                domProps: { value: visitor.fullName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      visitor,
                                      "fullName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group col-lg-3" },
                              [
                                _c("label", { attrs: { for: "resume" } }, [
                                  _vm._v("Pièce d'identité")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "name",
                                    options: _vm.identityDocTypes,
                                    placeholder: "Selectionner",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "singleMealType",
                                        fn: function(ref) {
                                          var type = ref.type
                                          return [_vm._v(_vm._s(type.name))]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: visitor.identityDocType,
                                    callback: function($$v) {
                                      _vm.$set(visitor, "identityDocType", $$v)
                                    },
                                    expression: "visitor.identityDocType"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "form-group col-lg-2" }, [
                              _c("label", { attrs: { for: "subject" } }, [
                                _vm._v("N° Identité")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: visitor.identityDocNo,
                                    expression: "visitor.identityDocNo"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "subject", type: "text" },
                                domProps: { value: visitor.identityDocNo },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      visitor,
                                      "identityDocNo",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group col-lg-3" },
                              [
                                _c("label", { attrs: { for: "message" } }, [
                                  _vm._v("Type de prise en charge")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "name",
                                    options: _vm.supportTypes,
                                    placeholder: "Selectionner",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "singleMealType",
                                        fn: function(ref) {
                                          var type = ref.type
                                          return [_vm._v(_vm._s(type.name))]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: visitor.supportType,
                                    callback: function($$v) {
                                      _vm.$set(visitor, "supportType", $$v)
                                    },
                                    expression: "visitor.supportType"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-lg-1 align-self-center" },
                              [
                                _vm.visitorSupportRequestForm.visitors.length >
                                  1 && index != 0
                                  ? _c(
                                      "a",
                                      { attrs: { href: "javascript:void(0)" } },
                                      [
                                        _c("i", {
                                          staticClass: "bx bx-trash-alt",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteRow(index)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      _c("input", {
                        staticClass: "btn btn-success mt-3 mt-lg-0",
                        attrs: { type: "button", value: "Ajouter" },
                        on: { click: _vm.AddformData }
                      })
                    ],
                    2
                  ),
                  _c("br"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Commentaires")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.visitorSupportRequestForm.notes,
                              expression: "visitorSupportRequestForm.notes"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { cols: "30", rows: "4" },
                          domProps: {
                            value: _vm.visitorSupportRequestForm.notes
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.visitorSupportRequestForm,
                                "notes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          disabled: _vm.$v.visitorSupportRequestForm.$invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }